export default {
    list: {
		data: null,
		links: null,
		meta: null
	},
	internalList: {
		data: null,
		links: null,
		meta: null
	}
}
