export const SET_USERS_LIST_DATA = (state, data) => {
    state.list.data = data.data;
    state.list.links = data.links;
    state.list.meta = {
      current_page: data.current_page,
      from: data.from,
      to:  data.to,
      total: data.total,
      perPage: data.per_page
  }
}
export const SET_INTERNAL_USERS_LIST_DATA = (state, data) => {
    state.internalList.data = data.data;
    state.internalList.links = data.links;
    state.internalList.meta = {
      current_page: data.current_page,
      from: data.from,
      to:  data.to,
      total: data.total,
      perPage: data.per_page
  }
}