export const getListData = (state) => {
	return state.list.data;
}
export const getListMeta = (state) => {
	return state.list.meta;
}
export const getListLinks = (state) => {
	return state.list.links;
}
export const getCurrentPage = (state) => {
	return state.list.current_page;
}
