export const getSlackTranList = ({ commit, dispatch }, query = '') => {
    let seller_id = 0;
    query = query ? query+'&seller_id=' + seller_id : +'?seller_id=' + seller_id;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/transactions-list-slack'+ query).then((response) => {
        commit('SET_ST_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getDiskTranList = ({ commit, dispatch }, query = '') => {
    let seller_id = 0;
    query = query ? query+'&seller_id=' + seller_id : +'?seller_id=' + seller_id;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/transactions-list-disk'+ query).then((response) => {
        commit('SET_ST_LIST_DATA', response.data.data);
        return response.data.data;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const tranPaymentConfirmation = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/tranPaymentConfirmation',payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false;
    })
}