export default {
	showDeleteArt: false,
	list: {
		data: null,
		links: null,
		meta: null,
		current_page: null,
        prodcutCategoeis: null
	},
	permissions:[]
}
