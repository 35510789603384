export const getListData = (state) => {
	return state.list.data;
}

export const getListLinks = (state) => {
	return state.list.links;
}

export const getListMeta = (state) => {
	return state.list.meta;
}
export const getInternalListData = (state) => {
	return state.internalList.data;
}

export const getInternalListLinks = (state) => {
	return state.internalList.links;
}

export const getInternalListMeta = (state) => {
	return state.internalList.meta;
}